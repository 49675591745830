import React, { useEffect } from 'react';
import '../styles/loading_screen.css';
import { motion } from 'framer-motion';
const LoadingScreen = () => {

    var loading_texts = ["Generating Quiz", "Loading Questions", "Loading Answers", "Loading Images"];

    useEffect(() => {
        var i = 0;
        var interval = setInterval(() => {
            document.querySelector(".loading-screen-text").innerHTML = loading_texts[i];
            i++;
            if (i === loading_texts.length) {
                i = 0;
            }
        }, 1500);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="loading-screen">
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5, duration: 1.5 }}
                className="loading-screen-background">
                <h1 className="loading-screen-text">Loading...</h1>

                <div className="custom-loader"></div>
            </motion.div>
        </div>
    );
};

export default LoadingScreen;

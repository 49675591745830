import React from 'react';
import './navbar.css';
const Navbar = ({ title }) => {
    return (
        <div className='nav-bar'>
            <a className='nav-bar-close-button' href='/'>X</a>
            <h2 className='nav-bar-page-title'>{title}</h2>

        </div>
    );
};

export default Navbar;

// Components/NavBar.js
import { useParams } from 'react-router-dom';
import axios from 'axios';
import '../styles/create_quiz.css';
import { useState } from 'react';
import { motion } from "framer-motion";
import { useEffect } from 'react';
import ReactGA from "react-ga4";


const CreateQuiz = () => {

    ReactGA.send({ hitType: "pageview", page: "/create-quiz" });
    var quizzes = {};

    var loading_texts = ["Generating Quiz", "Loading Questions", "Loading Answers", "Loading Images"];

    useEffect(() => {
        var i = 0;
        var interval = setInterval(() => {
            document.querySelector(".loading-screen-text").innerHTML = loading_texts[i];
            i++;
            if (i === loading_texts.length) {
                i = 0;
            }
        }, 1500);
        return () => clearInterval(interval);
    }, []);


    const [quizSettings, setQuizSettings] = useState({
        numQuestions: 5,
        difficulty: "easy",
        quizType: "Binary_Search_Tree"
    });

    // handle change event of input
    const handleChange = (e) => {
        setQuizSettings({ ...quizSettings, [e.target.name]: e.target.value });
    }



    function createQuiz() {
        // https://dsa-practice.onrender.com/
        // http://localhost:5000/

        document.querySelector(".loading-screen").style.display = "block";
        document.querySelector(".main_content").style.display = "none";
        axios.post('https://dsa-practice.onrender.com/createNewQuiz', quizSettings)
            .then(function (response) {
                // clear the local storage
                localStorage.clear();

                document.querySelector(".loading-screen").style.display = "none";
                document.querySelector(".main_content").style.display = "block";

                var quiz_data = response.data.quiz_data;
        
                localStorage.setItem(response.data.quizID, quiz_data);
                window.location.href = "/quiz/" + response.data.quizID;

            })
            .catch(function (error) {
                console.log(error);
                document.querySelector(".loading-screen").style.display = "none";
                document.querySelector(".main_content").style.display = "block";

            });
    }

    return (
        <>
            <div className='wrapper'>
                <div className="loading-screen" style={{ display: 'none' }}>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.5, duration: 1.5 }}
                        className="loading-screen-background">
                        <h1 className="loading-screen-text">Loading...</h1>

                        <div className="custom-loader"></div>
                    </motion.div>
                </div>
                <div className="main_content">

                    <h1 className='header'>DSA Practice</h1>
                    {/* <h1>Create Quiz Page</h1>
                <button onClick={createQuiz}>Create Quiz</button>
                 */}

                    <div className="container">
                        <h1>Create Quiz</h1>

                        <label htmlFor="quizType">Quiz Type</label>
                        <select id="quizType" name="quizType" onChange={handleChange}>
                            <option disabled>────DATA STRUCTURES──────</option>
                            <option value="Binary_Search_Tree">Binary Search Tree</option>
                            <option value="AVL_Tree">AVL Tree</option>
                            <option value="heap_quiz">Heap</option>
                            <option value="linkedlist_quiz">Linked List</option>
                            <option value="stack_quiz">Stack</option>
                            <option value="queue_quiz">Queue</option>
                            <option value="hash_quiz">Hash</option>
                            <option value="graph_computerscience_quiz">Graph</option>
                            <option value="trie_quiz">Trie</option>
                            <option disabled>────ALGORITHMS──────</option>
                            {/* add all the algorithms */}
                            <option value="bubble_sort">Bubble Sort</option>
                            <option value="selection_sort">Selection Sort</option>
                            <option value="insertion_sort">Insertion Sort</option>
                            <option value="merge_sort">Merge Sort</option>
                            <option value="quick_sort">Quick Sort</option>
                            <option value="radix_sort">Radix Sort</option>
                            <option value="binary_sort">Binary Search</option>
                            <option value="Breadth_First_Search">Breadth First Search</option>
                            <option value="Depth_First_Search">Depth First Search</option>
                            <option value="Dijkstra_Algorithm">Dijkstra's Algorithm</option>
                            <option value="Kruskal_Algorithm">Kruskal's Algorithm</option>
                            <option value="Prim_Algorithm">Prim's Algorithm</option>

                        </select>
                        {/* <label for="type">Quiz Type</label>
                        <select id="type" name="type">
                            <option value="mcq">Multiple Choice</option>
                            <option value="tf">True/False</option>
                            <option value="sa">Short Answer</option>
                        </select> */}
                        <label htmlFor="difficulty" onChange={handleChange}>Difficulty</label>
                        <select id="difficulty" name="difficulty" onChange={handleChange}>
                            <option value="easy" defaultValue>Easy</option>
                            <option value="medium">Medium</option>
                            <option value="hard">Hard</option>
                        </select>
                        <label htmlFor="numQuestions" >Number of Questions</label>
                        <input type="number" id="numQuestions" name="numQuestions" min="1" onChange={handleChange} />

                    

                        {/* <div class="quiz_settings box">
                        <h2>Quiz Settings</h2>
                        <div class="quiz_settings_parent">
                            <div class="quiz_setting_1">
                                <h3># of questions (max 10)</h3>
                                <input type="number" min="1" max="10" name="numQuestions" onChange={handleChange} />
                            </div>
                            <div class="quiz_setting_2">
                                <h3>Select Difficulty</h3>
                                <select name="difficulty" onChange={handleChange}>
                                    <option value="" disabled selected>Select Difficulty</option>
                                    <option value="easy">Easy</option>
                                    <option value="medium">Medium</option>
                                    <option value="hard">Hard</option>
                                </select>
                            </div>
                        </div>
                    </div> */}

                        {/* <div class="description_div">
                        <p>{currentQuiz.description}</p>
                    </div> */}

                        <div >
                            <button className="quiz_settings_button" onClick={createQuiz}>Generate Quiz!</button>
                        </div>

                        {/* <div>
                            <a href='https://buy.stripe.com/00g3co8A97RW4V27ss'>Buy 1000 Questions</a>
                        </div> */}
                    </div>

                </div>
            </div>
        </>
    );
};

export default CreateQuiz;
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/articleSysemComponents/navbar';
import QuickQuestion from '../components/articleSysemComponents/quickQuestion';
import ArticleSystem from '../components/article_system';

import ArticleTitle from "../components/articleSysemComponents/ArticleTitle";
import HorizontalLine from "../components/articleSysemComponents/HorizontalLine";
import ArticleDescription from "../components/articleSysemComponents/ArticleDescription";
import ArticleSectionHeader from "../components/articleSysemComponents/ArticleSectionHeader";
import ArticleParagraph from "../components/articleSysemComponents/ArticleParagraph";
import ArticleList from "../components/articleSysemComponents/ArticleList";
import CodeSection from "../components/articleSysemComponents/CodeSection";



const HashtableArticle = () => {

    return (
        <>
            <Helmet>
                <title>Hashtable Data Structure</title>
                <meta name="description" content="Explore the Hashtable data structure, understand its principles, and learn about different types of hashing and collision resolution strategies. Dive into implementations and applications of hashtables in programming." />
                <meta name="keywords" content="Data Structures, Algorithms, Hashtable, Hashing, Collision Resolution, Java, Python, C++, JavaScript, Programming, Code, Implementation, Applications, Computer Science" />
            </Helmet>


            <Navbar title={'HashTables'} />

            <div className="article_container">
                <ArticleTitle text={"Exploring Hashtables: A Powerful Data Structure"} />
                <HorizontalLine />
                <ArticleDescription
                    text={
                        "Dive into the world of Hashtables, a key-value based data structure, and discover its properties, operations, collision resolution techniques, and applications."
                    }
                />
                <HorizontalLine />
                <ArticleSectionHeader text={"Introduction to Hashtables"} />
                <ArticleParagraph
                    text={
                        "A Hashtable, also known as a Hashmap, is a data structure that stores key-value pairs where each unique key maps to a specific value. It uses a hashing function to compute an index for each key, allowing for efficient retrieval and storage of data."
                    }
                />
                <ArticleParagraph
                    text={
                        "Key characteristics of Hashtables include:"
                    }
                />
                <ArticleList
                    items={[
                        "Fast access and retrieval of values based on keys.",
                        "Keys are unique within the hashtable.",
                        "Uses a hashing function to generate indices for keys.",
                        "Collision handling mechanisms to resolve hash collisions.",
                        "Commonly used in associative arrays, databases, and caches.",
                    ]}
                />
                <ArticleSectionHeader text={"Basic Structure of a Hashtable"} />
                <ArticleParagraph
                    text={
                        "A Hashtable consists of an array of buckets, where each bucket can store multiple key-value pairs. The hashing function calculates the index of each key, determining the bucket where the key-value pair will be stored."
                    }
                />
                <ArticleSectionHeader text={"Hashing Function"} />
                <ArticleParagraph
                    text={
                        "The hashing function takes a key as input and computes an index (hash code) where the corresponding value will be stored. It should generate unique indices for different keys to minimize collisions."
                    }
                />
                <ArticleSectionHeader text={"Collision Resolution Techniques"} />
                <ArticleParagraph
                    text={
                        "Collisions occur when two different keys hash to the same index. There are several methods to handle collisions:"
                    }
                />
                <ArticleList
                    items={[
                        "Separate Chaining: Each bucket stores a linked list or another data structure to handle multiple key-value pairs.",
                        "Open Addressing: Find an alternate empty slot in the hashtable for the colliding key.",
                        "Linear Probing: Search sequentially for the next available slot in the hashtable.",
                        "Quadratic Probing: Use a quadratic function to search for an empty slot.",
                    ]}
                />
                <ArticleSectionHeader text={"Operations on Hashtables"} />
                <ArticleParagraph
                    text={
                        "Common operations performed on Hashtables include insertion, deletion, retrieval, and updating of key-value pairs."
                    }
                />
                <ArticleSectionHeader text={"Insertion Operation"} />
                <ArticleParagraph
                    text={
                        "Inserting a key-value pair involves calculating the hash code for the key and storing the pair in the corresponding bucket."
                    }
                />
                <ArticleSectionHeader text={"Retrieval Operation"} />
                <ArticleParagraph
                    text={
                        "Retrieving a value based on a key requires computing the hash code, locating the bucket, and fetching the value."
                    }
                />
                <ArticleSectionHeader text={"Deletion Operation"} />
                <ArticleParagraph
                    text={
                        "Deleting a key-value pair involves finding the key's index, locating the pair, and removing it from the bucket."
                    }
                />
                <ArticleSectionHeader text={"Applications of Hashtables"} />
                <ArticleList
                    items={[
                        "Storing Data: Efficient storage and retrieval of key-value pairs.",
                        "Implementing Caches: Used in caching mechanisms to store frequently accessed data.",
                        "Databases: Used in database indexing for quick data retrieval.",
                        "Language Libraries: Many programming languages provide built-in Hashtable implementations.",
                        "Symbol Tables: Used in compilers and interpreters for storing variables and identifiers.",
                    ]}
                />
                <ArticleSectionHeader text={"Advantages of Hashtables"} />
                <ArticleList
                    items={[
                        "Fast retrieval of values based on keys.",
                        "Efficient storage and lookup of key-value pairs.",
                        "Flexible and scalable for handling large datasets.",
                        "Collision resolution techniques ensure data integrity.",
                    ]}
                />
                <ArticleSectionHeader text={"Disadvantages of Hashtables"} />
                <ArticleList
                    items={[
                        "Space Complexity: Requires additional memory for storage.",
                        "Hashing Function Complexity: Designing an efficient hashing function is crucial.",
                        "Performance Degradation: Collisions can impact retrieval speed.",
                    ]}
                />
                <ArticleSectionHeader text={"Conclusion"} />
                <ArticleParagraph
                    text={
                        "Hashtables are powerful and versatile data structures that offer efficient storage, retrieval, and management of key-value pairs. Understanding how to implement and use Hashtables is essential for developing high-performance applications and algorithms."
                    }
                />
                <QuickQuestion
                    text={
                        <>
                            {"What is the hash value of the key 90 using the given hash function?"}
                            <pre style={{ fontSize: '10px', marginLeft: "-100px" }}>
                                {`
                public int hashFunc (int item) {
                    int value = item * item; // square the item;
                    value /= 256; // discard the low order 8 bits
                    return Math.abs(value % 65536); // return item in range 0 to 65535;
                }
                                `}
                            </pre>
                        </>
                    }
                    choices={["90", "256", "65536", "Your calculated value"]}
                    answer={"Your calculated value"}
                    questionShown={true}
                />
                <QuickQuestion text={"If there is a collision at index 5 in a hash table with 20 positions, what would be the new index using rehashing as the method of collision resolution?"} choices={["5", "1", "Your calculated value", "None of the above"]} answer={"Your calculated value"} questionShown={true} />

                <QuickQuestion text={"If there is a collision at index 3 in a hash table with 20 positions, what would be the new index using quadratic hashing as the method of collision resolution?"} choices={["3", "9", "Your calculated value", "None of the above"]} answer={"Your calculated value"} questionShown={true} />

                <QuickQuestion text={"If there is a collision at index 2 in a hash table with 10 positions, how would you resolve it using chaining?"} choices={["Move to the next index", "Store the colliding items in a linked list at the same index", "Recalculate the hash function", "None of the above"]} answer={"Store the colliding items in a linked list at the same index"} questionShown={true} />

                <QuickQuestion text={"If there is a collision at index 1 in a hash table with 5 positions, how would you resolve it using linear probing?"} choices={["Move to the next index", "Store the colliding items in a linked list at the same index", "Recalculate the hash function", "None of the above"]} answer={"Move to the next index"} questionShown={true} />

                <QuickQuestion text={"What should you do when the load factor of a hash table exceeds 50%?"} choices={["Do nothing", "Remove some items", "Double the size of the table", "Halve the size of the table"]} answer={"Double the size of the table"} questionShown={true} />

            </div>
        </>
    );
};

export default HashtableArticle;

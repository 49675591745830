import BST from "../images/BST.png";
import AVL from "../images/AVL.png";
import HEAP from "../images/HEAP.png";
import LL from "../images/LL.png";
import STACK from "../images/STACK.png";
import QUEUE from "../images/QUEUE.png"; 
import HASHTABLE from "../images/HASHTABLE.png";
import GRAPH from "../images/GRAPH.png";
import TRIE from "../images/TRIE.png";

import BinarySearch from "../images/algorithms/Binary_Search.png";
import LinearSearch from "../images/algorithms/Linear_Search.png";
import SelectionSort from "../images/algorithms/Selection_Sort.png";
import InsertionSort from "../images/algorithms/Insertion_Sort.png";
import BubbleSort from "../images/algorithms/Bubble_Sort.png";
import MergeSort from "../images/algorithms/Merge_Sort.png";
import QuickSort from "../images/algorithms/Quick_Sort.png";
import CountingSort from "../images/algorithms/Counting_Sort.png";
import RadixSort from "../images/algorithms/Radix_Sort.png";
import BucketSort from "../images/algorithms/Bucket_Sort.png";
import ShellSort from "../images/algorithms/Shell_Sort.png";
import HeapSort from "../images/algorithms/Heap_Sort.png";
import BreadthFirstSearch from "../images/algorithms/Breadth_First_Search.png";



var datastructres = [
    {
        name: "Binary Search Tree",
        image: BST,
        link: "/article/binary-search-tree",
        description: "Master essential skills in Binary Search Trees, including how to implement a Binary Search Tree, how to insert and delete nodes in a Binary Search Tree, and how to traverse a Binary Search Tree."
    },
    {
        name: "AVL Tree",
        image: AVL,
        link: "/article/avl-tree",
        description: "Master essential skills in AVL Trees, including how to implement an AVL Tree, how to insert and delete nodes in an AVL Tree, and how to traverse an AVL Tree."
    },
    {
        name: "Heap",
        image: HEAP,
        link: "/article/heaps",
        description: "Master essential skills in Heaps, including how to implement a Heap, how to insert and delete nodes in a Heap, and how to traverse a Heap."
    },
    {
        name: "Linked List",
        image: LL,
        link: "/article/linked-list",
        description: "Master essential skills in Linked Lists, including how to implement a Linked List, how to insert and delete nodes in a Linked List, and how to traverse a Linked List."
    },
    {
        name: "Stack",
        image: STACK,
        link: "/article/stack",
        description: "Master essential skills in Stacks, including how to implement a Stack, how to push and pop elements in a Stack, and how to traverse a Stack."
    },
    {
        name: "Queue",
        image: QUEUE,
        link: "/article/queue",
        description: "Master essential skills in Queues, including how to implement a Queue, how to enqueue and dequeue elements in a Queue, and how to traverse a Queue."
    },
    {
        name: "HashTables",
        image: HASHTABLE,
        link: "/article/hashtables",
        description: "Master essential skills in Hash Tables, including how to implement a Hash Table, how to insert and delete elements in a Hash Table, and how to traverse a Hash Table."
    },
    // {
    //     name: "Graph",
    //     image: GRAPH,
    //     link: "/article/graph",
    //     description: "Master essential skills in Graphs, including how to implement a Graph, how to insert and delete nodes in a Graph, and how to traverse a Graph."
    // },
    // {
    //     name: "Trie",
    //     image: TRIE,
    //     link: "/article/trie",
    //     description: "Master essential skills in Tries, including how to implement a Trie, how to insert and delete nodes in a Trie, and how to traverse a Trie."
    // },

]

var algorithms = [
    {
        name: "Selection Sort",
        image: SelectionSort,
        // link: "/article/selection_sort",
        link: "/article/under_construction",
        description: "Master essential skills in Selection Sort, including how to implement Selection Sort, how to sort an array using Selection Sort, and how to analyze the time complexity of Selection Sort."

    },
    {
        name: "Insertion Sort",
        image: InsertionSort,
        // link: "/article/insertion_sort",
        link: "/article/under_construction",
        description: "Master essential skills in Insertion Sort, including how to implement Insertion Sort, how to sort an array using Insertion Sort, and how to analyze the time complexity of Insertion Sort."

    },
    {
        name: "Bubble Sort",
        image: BubbleSort,
        // link: "/article/bubble_sort",
        link: "/article/under_construction",
        description: "Master essential skills in Bubble Sort, including how to implement Bubble Sort, how to sort an array using Bubble Sort, and how to analyze the time complexity of Bubble Sort."

    },
    {
        name: "Merge Sort",
        image: MergeSort,
        // link: "/article/merge_sort",
        link: "/article/under_construction",
        description: "Master essential skills in Merge Sort, including how to implement Merge Sort, how to sort an array using Merge Sort, and how to analyze the time complexity of Merge Sort."

    },
    {
        name: "Quick Sort",
        image: QuickSort,
        // link: "/article/quick_sort",
        link: "/article/under_construction",
        description: "Master essential skills in Quick Sort, including how to implement Quick Sort, how to sort an array using Quick Sort, and how to analyze the time complexity of Quick Sort."

    },
    // {
    //     name: "Counting Sort",
    //     image: CountingSort,
    //     link: "/article/counting_sort",
    //     description: "Master essential skills in Counting Sort, including how to implement Counting Sort, how to sort an array using Counting Sort, and how to analyze the time complexity of Counting Sort."

    // },
    {
        name: "Radix Sort",
        image: RadixSort,
        // link: "/article/radix_sort",
        link: "/article/under_construction",
        description: "Master essential skills in Radix Sort, including how to implement Radix Sort, how to sort an array using Radix Sort, and how to analyze the time complexity of Radix Sort."

    },
    // {
    //     name: "Bucket Sort",
    //     image: BucketSort,
    //     link: "/article/bucket_sort",
    //     description: "Master essential skills in Bucket Sort, including how to implement Bucket Sort, how to sort an array using Bucket Sort, and how to analyze the time complexity of Bucket Sort."

    // },
    {
        name: "Shell Sort",
        image: ShellSort,
        // link: "/article/shell_sort",
        link: "/article/under_construction",
        description: "Master essential skills in Shell Sort, including how to implement Shell Sort, how to sort an array using Shell Sort, and how to analyze the time complexity of Shell Sort."

    },
    {
        name: "Heap Sort",
        image: HeapSort,
        // link: "/article/heap_sort",
        link: "/article/under_construction",
        description: "Master essential skills in Heap Sort, including how to implement Heap Sort, how to sort an array using Heap Sort, and how to analyze the time complexity of Heap Sort."
    },
    {
        name: "Binary Search",
        image: BinarySearch,
        // link: "/create/binary_search",
        link: "/article/under_construction",
        description: "Master essential skills in Binary Search, including how to implement Binary Search, how to search an array using Binary Search, and how to analyze the time complexity of Binary Search."

    },
    {
        name: "Linear Search",
        image: LinearSearch,
        // link: "/create/linear_search",
        link: "/article/under_construction",
        description: "Master essential skills in Linear Search, including how to implement Linear Search, how to search an array using Linear Search, and how to analyze the time complexity of Linear Search."

    },
    // {
    //     name: "Breadth First Search",
    //     image: BreadthFirstSearch,
    //     link: "/create/breadth_first_search",
    //     description: "Master essential skills in Breadth First Search, including how to implement Breadth First Search, how to search a graph using Breadth First Search, and how to analyze the time complexity of Breadth First Search."
    // },
]

export { datastructres, algorithms };
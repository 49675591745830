import React from 'react';
import './css/article_complete.css';
const ArticleTitle = ({ text }) => {
    return (
        <div className="article_title_component">
            <h1>{text}</h1>
        </div>
    );
};

export default ArticleTitle;

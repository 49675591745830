import Navbar from "../components/articleSysemComponents/navbar";
import React from "react";
import "../styles/article.css";
import QuickQuestion from "../components/articleSysemComponents/quickQuestion";
import { Helmet } from "react-helmet";

import ArticleTitle from "../components/articleSysemComponents/ArticleTitle";
import HorizontalLine from "../components/articleSysemComponents/HorizontalLine";
import ArticleDescription from "../components/articleSysemComponents/ArticleDescription";
import ArticleSectionHeader from "../components/articleSysemComponents/ArticleSectionHeader";
import ArticleParagraph from "../components/articleSysemComponents/ArticleParagraph";
import ArticleList from "../components/articleSysemComponents/ArticleList";
import CodeSection from "../components/articleSysemComponents/CodeSection";


const HeapsArticle = () => {
    return (
        <>
            <Helmet>
                <title>DSA Practice | Heaps</title>
                <meta name="description" content="Learn about the heap data structure, a complete binary tree, and its operations such as heapify, insert, delete, peek, and extract-max/min." />
            </Helmet>

            <Navbar
                title={"Heaps"}
            />
            <div className="article_container">
                <ArticleTitle text={"Understanding Heaps: Max Heaps and Min Heaps"} />
                <HorizontalLine />
                <ArticleDescription
                    text={
                        "Explore the concept of Heaps, including Max Heaps and Min Heaps, two essential data structures for efficient priority queue implementations."
                    }
                />
                <HorizontalLine />
                <ArticleSectionHeader text={"Introduction to Heaps"} />
                <ArticleParagraph
                    text={
                        "Heaps are specialized binary trees that satisfy the heap property. They come in two forms: Max Heaps and Min Heaps. Heaps are particularly useful for implementing priority queues, where elements with higher (or lower) priority need to be processed first."
                    }
                />
                <ArticleSectionHeader text={"Max Heap"} />
                <ArticleParagraph
                    text={
                        "In a Max Heap, the value of each parent node is greater than or equal to the values of its children nodes. This means that the largest element is always at the root."
                    }
                />
                <ArticleParagraph
                    text={
                        "Key characteristics of a Max Heap include:"
                    }
                />
                <ArticleList
                    items={[
                        "The value of any node is greater than or equal to the values of its children.",
                        "The highest element is at the root.",
                        "Removing the root (max element) results in the next largest element becoming the new root.",
                    ]}
                />
                <ArticleSectionHeader text={"Min Heap"} />
                <ArticleParagraph
                    text={
                        "In a Min Heap, the value of each parent node is less than or equal to the values of its children nodes. This ensures that the smallest element is at the root."
                    }
                />
                <ArticleParagraph
                    text={
                        "Key characteristics of a Min Heap include:"
                    }
                />
                <ArticleList
                    items={[
                        "The value of any node is less than or equal to the values of its children.",
                        "The smallest element is at the root.",
                        "Removing the root (min element) results in the next smallest element becoming the new root.",
                    ]}
                />
                <ArticleSectionHeader text={"Basic Structure of a Heap"} />
                <ArticleParagraph
                    text={
                        "A heap is typically represented as an array, where the relationship between parent and child nodes is determined by their indices."
                    }
                />
                <ArticleParagraph
                    text={
                        "For any node at index 'i', its left child is at index '2i + 1' and its right child is at index '2i + 2'. Similarly, the parent of a node at index 'i' is at index '(i - 1) / 2'. This array-based representation allows for efficient storage and manipulation of heap elements."
                    }
                />
                <ArticleSectionHeader text={"Operations on Heaps"} />
                <ArticleParagraph
                    text={
                        "The primary operations on heaps include insertion and deletion."
                    }
                />
                <ArticleSectionHeader text={"Insertion Operation"} />
                <ArticleParagraph
                    text={
                        "When inserting a new element into a heap, it is placed at the next available position (usually at the end of the array). The heap property is then restored by 'heapifying up' the newly inserted element."
                    }
                />
                <ArticleSectionHeader text={"Deletion Operation"} />
                <ArticleParagraph
                    text={
                        "Deleting an element from a heap involves removing the root (for both Max Heap and Min Heap) and then restoring the heap property by 'heapifying down' the replacement element."
                    }
                />
                <ArticleSectionHeader text={"Heapify Up (Bubble Up)"} />
                <ArticleParagraph
                    text={
                        "Heapify Up is the process of moving a node up in the heap to satisfy the heap property. This is typically used during insertion."
                    }
                />
                <ArticleSectionHeader text={"Heapify Down (Bubble Down)"} />
                <ArticleParagraph
                    text={
                        "Heapify Down is the process of moving a node down in the heap to satisfy the heap property. This is used during deletion."
                    }
                />
                <ArticleSectionHeader text={"Time Complexity of Heap Operations"} />
                <ArticleParagraph
                    text={
                        "The time complexity of basic heap operations is crucial for understanding their efficiency."
                    }
                />
                <ArticleList
                    items={[
                        "Insertion: O(log n)",
                        "Deletion: O(log n)",
                        "Heapify Up: O(log n)",
                        "Heapify Down: O(log n)",
                        "Building a Heap from an array: O(n)",
                    ]}
                />
                <ArticleSectionHeader text={"Conclusion"} />
                <ArticleParagraph
                    text={
                        "Heaps, whether Max Heaps or Min Heaps, are powerful data structures for managing priority-based operations efficiently. Their array-based representation and heap property ensure quick access to the highest (or lowest) priority element."
                    }
                />
            <QuickQuestion text={"Develop a Max Heap with these set of numbers: 5, 3, 7, 2, 4, 6, 8. What is the root?"} choices={["5", "3", "7", "2"]} answer={"8"} questionShown={true} />
            <QuickQuestion text={"Develop a Min Heap with these set of numbers: 5, 3, 7, 2, 4, 6, 8. What is the root?"} choices={["5", "3", "7", "2"]} answer={"2"} questionShown={true} />
            <QuickQuestion text={"In a Max Heap, which child of the root has a higher value?"} choices={["Left", "Right", "Both are equal", "Cannot be determined"]} answer={"Cannot be determined"} questionShown={true} />
            <QuickQuestion text={"In a Min Heap, which child of the root has a lower value?"} choices={["Left", "Right", "Both are equal", "Cannot be determined"]} answer={"Cannot be determined"} questionShown={true} />
            <QuickQuestion text={"What is the result of extract-max operation in a Max Heap with these set of numbers: 5, 3, 7, 2, 4, 6, 8?"} choices={["5", "3", "7", "8"]} answer={"8"} questionShown={true} />
            <QuickQuestion text={"What is the result of extract-min operation in a Min Heap with these set of numbers: 5, 3, 7, 2, 4, 6, 8?"} choices={["5", "3", "7", "2"]} answer={"2"} questionShown={true} />
            <QuickQuestion text={"What is the root of a Max Heap after extract-max operation with these set of numbers: 5, 3, 7, 2, 4, 6, 8?"} choices={["5", "3", "7", "2"]} answer={"7"} questionShown={true} />
            <QuickQuestion text={"What is the root of a Min Heap after extract-min operation with these set of numbers: 5, 3, 7, 2, 4, 6, 8?"} choices={["5", "3", "7", "2"]} answer={"3"} questionShown={true} />
        </div >
        </>
    );
};

export default HeapsArticle;

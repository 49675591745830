import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import '../styles/quiz.css';
import confetti from 'canvas-confetti';
import { motion } from "framer-motion";
import ResultsPage from './results';

const Quiz = () => {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const [quizCompleted, setQuizCompleted] = useState(false);
    const [score, setScore] = useState(0);
    const [quizData, setQuizData] = useState(null);
    const [showNextButton, setShowNextButton] = useState(false);

    const { quizID } = useParams();

    function customAtob(encodedStr) {
        
      
        // Use atob for the rest
        return atob(encodedStr);
      }

    useEffect(() => {
        // loop through the local storage and get the quiz data for the quizID
        for (var i = 0; i < localStorage.length; i++) {
            var key = localStorage.key(i);
            if (key === quizID) {
                // get the quiz data
                var quiz_data = localStorage.getItem(key);
                // decode the quiz data
                var decoded_quiz = JSON.parse(customAtob(quiz_data));
            
                setQuizData(decoded_quiz);
            }
        }

        // get the local storage quiz data
        var quiz_data = localStorage.getItem(quizID);

        if (quiz_data) {
            try {
                var decodedJsonString = atob(quiz_data);
            
                var decodedQuizData = JSON.parse(decodedJsonString);
                setQuizData(decodedQuizData);
            } catch (error) {
                console.error("Error decoding or parsing quiz data:", error);
                // Handle the error as needed (e.g., show an error message to the user)
            }
        } else {
            console.error("Quiz data not found in local storage");
            // clear the local storage
            localStorage.clear();
            window.location.href = "/";

        }

        setSelectedOption(null);
        setShowNextButton(false);
    }, [quizID, currentQuestion]);


    const handleOptionSelect = (selectedAnswer) => {
        // If an option is already selected, do nothing
        if (selectedOption !== null || showNextButton) {
            return;
        }

        setSelectedOption(selectedAnswer);

        if (selectedAnswer === quizData.questions[currentQuestion].answer) {

            // Correct answer
            setScore(score + 1);

            // show confetti
            var duration = 2 * 1000;
            var animationEnd = Date.now() + duration;
            var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

            function randomInRange(min, max) {
                return Math.random() * (max - min) + min;
            }

            var interval = setInterval(function () {
                var timeLeft = animationEnd - Date.now();

                if (timeLeft <= 0) {
                    return clearInterval(interval);
                }

                var particleCount = 50 * (timeLeft / duration);
                // since particles fall down, start a bit higher than random
                confetti(Object.assign({}, defaults, {
                    particleCount,
                    origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }
                }));
                confetti(Object.assign({}, defaults, {
                    particleCount,
                    origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }
                }));
            }, 250);



            quizData.questions[currentQuestion].options.forEach((option, index) => {
                if (option !== selectedAnswer) {
                    document.querySelectorAll('.answer')[index].classList.add('incorrect');
                }
            });
        } else {
            // Incorrect answer
            quizData.questions[currentQuestion].options.forEach((option, index) => {
                if (option === selectedAnswer) {
                    document.querySelectorAll('.answer')[index].classList.add('selected');
                }
            });
        }

        // Show the next button
        setShowNextButton(true);
    };

    const handleNextQuestion = () => {
        // Move to the next question
        if (currentQuestion + 1 < quizData.questions.length) {
            setCurrentQuestion(currentQuestion + 1);


            // Remove the green background from the correct option
            quizData.questions[currentQuestion].options.forEach((option, index) => {
                if (option !== quizData.questions[currentQuestion].answer) {
                    document.querySelectorAll('.answer')[index].classList.remove('incorrect');
                }
            });

            // Remove the green background from the correct option
            quizData.questions[currentQuestion].options.forEach((option, index) => {
                if (option === quizData.questions[currentQuestion].answer) {
                    document.querySelectorAll('.answer')[index].classList.remove('selected');
                }
            });

        } else {
            // Quiz completed
            setQuizCompleted(true);
        }
    };


    return (
        <>
            <div className='quizHeader'>
                <a href='/'><h1>DSA Practice</h1></a>
            </div>

            {quizData && !quizCompleted && (
                <div id="quiz-container">
                {quizData && (
                    <div>
                        <div id="question-section">
                            <h2>{quizData.questions[currentQuestion].question}</h2>
                        </div>
                        <div id="answers-section">
                            {quizData.questions[currentQuestion].options.map((option, index) => (
                                <div
                                    key={index}
                                    className={`answer${selectedOption === option ? ' selected' : ''}`}
                                    onClick={() => handleOptionSelect(option)}
                                >
                                    {option}
                                </div>
                            ))}
                        </div>
                        {showNextButton && (
                            <button onClick={handleNextQuestion} className='nextQuestionButton'>Next Question</button>
                        )}
                    </div>
                )}
            </div>
            )}

            {quizCompleted && (
                <ResultsPage score={score} num_questions={quizData.questions.length} />
            )}
        </>
    );
}

export default Quiz;

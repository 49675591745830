import ArticleSystem from "../components/article_system";
import Navbar from "../components/articleSysemComponents/navbar";
import React, { useState, useEffect } from 'react';
import coverImage from "../images/BST.png"
import "../styles/article.css"
import QuickQuestion from "../components/articleSysemComponents/quickQuestion";
import { Helmet } from "react-helmet";
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/default-highlight';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import PracticeMaterialSection from "../components/practiceMaterialsSection";

import ArticleTitle from "../components/articleSysemComponents/ArticleTitle";
import HorizontalLine from "../components/articleSysemComponents/HorizontalLine";
import ArticleDescription from "../components/articleSysemComponents/ArticleDescription";
import ArticleSectionHeader from "../components/articleSysemComponents/ArticleSectionHeader";
import ArticleParagraph from "../components/articleSysemComponents/ArticleParagraph";
import ArticleList from "../components/articleSysemComponents/ArticleList";
import CodeSection from "../components/articleSysemComponents/CodeSection";



const BSTArticle = () => {

    return (
        <>
            <Helmet>
                <title>Binary Search Tree - Efficient Searching Data Structure</title>
                <meta name="description" content="Learn about Binary Search Trees (BSTs) - Learn about postorder traversal, preorder traversal, inorder traversal, searching, inserting a node, and deleting a node in a binary search tree! Understand the rules, traversal, and operations with examples." />
                <meta name="robots" content="index, follow" />
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="language" content="English" />
            </Helmet>

            <Navbar title={"Binary Search Tree"} />



            <div className="article_container" >

                <ArticleTitle text={"Understanding Binary Search Trees (BSTs)"} />
                <HorizontalLine />
                <ArticleDescription
                    text={
                        "Explore the fundamentals of Binary Search Trees (BSTs) - a key data structure in computer science."
                    }
                />
                <HorizontalLine />
                <ArticleSectionHeader text={"Introduction to BSTs"} />
                <ArticleParagraph
                    text={
                        "Binary Search Trees (BSTs) are a type of binary tree that maintain the property where the left child of a node contains values lesser than the node's value, and the right child contains values greater than the node's value. This unique property allows for efficient searching, insertion, and deletion of elements."
                    }
                />
                <ArticleSectionHeader text={"Key Characteristics of BSTs"} />
                <ArticleList
                    items={[
                        "Each node has at most two children - left and right.",
                        "The left subtree of a node contains values lesser than the node's value.",
                        "The right subtree of a node contains values greater than the node's value.",
                        "Nodes are arranged in a hierarchical order.",
                    ]}
                />
                <ArticleSectionHeader text={"Basic Structure of a Node in BST"} />
                <CodeSection
                    code={`
          class Node {
              int data;
              Node left;
              Node right;

              public Node(int data) {
                  this.data = data;
                  this.left = null;
                  this.right = null;
              }
          }
          `}
                />
                <ArticleParagraph
                    text={
                        "The structure of a node in a BST typically includes a data field and references to its left and right children. Here, 'data' represents the value stored in the node, while 'left' and 'right' are pointers to its left and right children, respectively."
                    }
                />
                <ArticleSectionHeader text={"Operations on BSTs"} />
                <ArticleParagraph
                    text={
                        "The primary operations on a BST include insertion, deletion, and search. These operations are guided by the properties of BSTs, ensuring the tree remains sorted and balanced."
                    }
                />
                <ArticleSectionHeader text={"Insertion Operation"} />
                <ArticleParagraph
                    text={
                        "To insert a new element into a BST, the tree is traversed based on the value of the new element. If the value is lesser than the current node, the left subtree is explored; if greater, the right subtree is explored. This continues until an appropriate empty spot is found, where the new element is placed."
                    }
                />
                <CodeSection
                    code={`
          public Node insert(Node root, int data) {
              if (root == null) {
                  return new Node(data);
              }

              if (data < root.data) {
                  root.left = insert(root.left, data);
              } else if (data > root.data) {
                  root.right = insert(root.right, data);
              }

              return root;
          }
          `}
                />
                <ArticleSectionHeader text={"Deletion Operation"} />
                <ArticleParagraph
                    text={
                        "Deleting a node from a BST involves several cases: a node with no children, a node with one child, and a node with two children. The tree is rearranged accordingly to maintain its BST properties."
                    }
                />
                <ArticleSectionHeader text={"Search Operation"} />
                <ArticleParagraph
                    text={
                        "Searching for a value in a BST is efficient due to its sorted nature. Starting from the root, the tree is traversed based on the comparison of the target value with the current node. If the value matches, the node is found; otherwise, the search continues in the appropriate subtree."
                    }
                />
                <ArticleSectionHeader text={"Balanced BSTs"} />
                <ArticleParagraph
                    text={
                        "Balanced BSTs ensure that the height of the tree remains minimal, leading to efficient operations. There are various methods to balance a BST, such as rotations and rebalancing techniques."
                    }
                />
                <ArticleSectionHeader text={"Traversals in BSTs"} />
                <ArticleParagraph
                    text={
                        "Traversing a BST involves visiting each node in a specific order. The three primary traversal methods are:"
                    }
                />

                <ArticleList
                    items={[
                        "Inorder Traversal: Visit left subtree, current node, right subtree.",
                        "Preorder Traversal: Visit current node, left subtree, right subtree.",
                        "Postorder Traversal: Visit left subtree, right subtree, current node."
                    ]}
                />

                <ArticleSectionHeader text={"Inorder Traversal"} />
                <ArticleParagraph
                    text={
                        "In inorder traversal, nodes are visited in the order of left subtree, current node, and right subtree. This traversal method results in nodes being visited in ascending order."
                    }
                />
                <CodeSection
                    code={`
            public void inorderTraversal(Node root) {
                if (root != null) {
                    inorderTraversal(root.left);
                    System.out.print(root.data + " ");
                    inorderTraversal(root.right);
                }
            }
            `}

                />

                <ArticleSectionHeader text={"Preorder Traversal"} />
                <ArticleParagraph
                    text={
                        "In preorder traversal, nodes are visited in the order of the current node, left subtree, and right subtree. This traversal method is useful for creating a copy of the tree."
                    }
                />
                <CodeSection

                    code={`
            public void preorderTraversal(Node root) {  
                if (root != null) {
                    System.out.print(root.data + " ");
                    preorderTraversal(root.left);
                    preorderTraversal(root.right);
                }
            }
            `}
                />

                <ArticleSectionHeader text={"Postorder Traversal"} />
                <ArticleParagraph
                    text={
                        "In postorder traversal, nodes are visited in the order of left subtree, right subtree, and current node. This traversal method is useful for deleting nodes in a tree."
                    }
                />
                <CodeSection
                    code={`
            public void postorderTraversal(Node root) { 
                if (root != null) {
                    postorderTraversal(root.left);
                    postorderTraversal(root.right);
                    System.out.print(root.data + " ");
                }
            }
            `}
                />

                {/* add a question asking about the root */}
                <QuickQuestion text={"Develop a Binary Tree with these set of numbers: 5, 3, 7, 2, 4, 6, 8. What is the root?"} choices={["5", "3", "7", "2"]} answer={"5"} questionShown={true}  />

                {/* add a question asking about leaves */}
                <QuickQuestion text={"Develop a Binary Tree with these set of numbers: 5, 3, 7, 2, 4, 6, 8. What are the leaves?"} choices={["5, 3, 7, 2, 4, 6, 8", "2, 4, 6, 8", "2, 4, 6, 8, 7"]} answer={"2, 4, 6, 8"}questionShown={true}  />

                <QuickQuestion text={"Develop a Binary Tree with these set of numbers: 5, 3, 7, 2, 4, 6, 8. What is height of the tree?"} choices={["1", "2", "3", "4"]} answer={"2"} questionShown={true}  />

                <QuickQuestion text={"Develop a Binary Tree with these set of numbers: 5, 3, 7, 2, 4, 6, 8. What is depth of the node with value 4?"} choices={["1", "2", "3", "4"]} answer={"2"} questionShown={true}  />

                <QuickQuestion text={"Develop a Binary Tree with these set of numbers: 5, 3, 7, 2, 4, 6, 8. What is the preorder traversal?"} choices={["5, 3, 2, 4, 7, 6, 8", "2, 3, 4, 5, 6, 7, 8", "2, 4, 3, 6, 8, 7, 5", "2, 4, 3, 6, 8, 7, 5"]} answer={"5, 3, 2, 4, 7, 6, 8"} questionShown={true}  />

                <QuickQuestion text={"Develop a Binary Tree with these set of numbers: 5, 3, 7, 2, 4, 6, 8. What is the inorder traversal?"} choices={["5, 3, 2, 4, 7, 6, 8", "2, 3, 4, 5, 6, 7, 8", "2, 4, 3, 6, 8, 7, 5", "2, 4, 3, 6, 8, 7, 5"]} answer={"2, 3, 4, 5, 6, 7, 8"} questionShown={true} />

                <QuickQuestion text={"Develop a Binary Tree with these set of numbers: 5, 3, 7, 2, 4, 6, 8. What is the postorder traversal?"} choices={["5, 3, 2, 4, 7, 6, 8", "2, 3, 4, 5, 6, 7, 8", "2, 4, 3, 6, 8, 7, 5", "2, 4, 3, 6, 8, 7, 5"]} answer={"2, 4, 3, 6, 8, 7, 5"} questionShown={true} />
            </div>
        </>
    );
};
    export default BSTArticle;

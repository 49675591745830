import React from 'react';
import "../styles/footer.css";

const Footer = () => {
    return (
        <footer className="footer-container">
            <div className="footer-content">
                <div className="footer-links">
                    <a href="/terms-of-use" className="footer-link">Terms of Use</a>
                    <span className="link-separator">|</span>
                    <a href="/privacy-policy" className="footer-link">Privacy Policy</a>
                    <span className="link-separator">|</span>
                    <a href="/cookie-policy" className="footer-link">Cookie Policy</a>
                </div>
                <div className="footer-branding">
                    DSAPractice
                </div>
                <a href="#" className="footer-preferences">Update cookies preferences</a>
            </div>
        </footer>
    );
};

export default Footer;
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from "react-ga4";
import { setChonkyDefaults } from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';

setChonkyDefaults({ iconComponent: ChonkyIconFA });

const root = ReactDOM.createRoot(document.getElementById('root'));

ReactGA.initialize("G-Z254B1T5QE");

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);


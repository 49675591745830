import ArticleSystem from "../components/article_system";
import Navbar from "../components/articleSysemComponents/navbar";
import React, { useState, useEffect } from "react";
import coverImage from "../images/AVL.png";
import "../styles/article.css";
import QuickQuestion from "../components/articleSysemComponents/quickQuestion";
import { Helmet } from "react-helmet";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

import ArticleTitle from "../components/articleSysemComponents/ArticleTitle";
import HorizontalLine from "../components/articleSysemComponents/HorizontalLine";
import ArticleDescription from "../components/articleSysemComponents/ArticleDescription";
import ArticleSectionHeader from "../components/articleSysemComponents/ArticleSectionHeader";
import ArticleParagraph from "../components/articleSysemComponents/ArticleParagraph";
import ArticleList from "../components/articleSysemComponents/ArticleList";
import CodeSection from "../components/articleSysemComponents/CodeSection";

const AVLArticle = () => {
    return (
        <>

            <Helmet>
                <meta name="title" content="Understanding AVL Trees: Construction and Functionality Explained" />
                <meta name="description" content="Get to know AVL Trees with our easy-to-understand guide. See how they are built and how they work. Learn why they are important in computer science!" />
                <meta name="keywords" content="AVL Trees, Data Structures, Computer Science, AVL Tree Construction, AVL, Tree Functionality, AVL Tree Explanation, AVL Tree Guide, Learning AVL Trees, AVL Tree Tutorial, Understanding AVL Trees ,AVL Tree Applications, AVL Tree BasicsAVL Tree for BeginnersAVL Tree for ExpertsAVL Tree Operations" />
                <meta name="robots" content="index, follow" />
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="language" content="English" />
            </Helmet>
            <Navbar title={"AVL Trees"} />

            <div className="article_container">

                <ArticleTitle text={"Understanding AVL Trees: Construction and Functionality Explained"} />
                <HorizontalLine />
                <ArticleDescription text={"Get to know AVL Trees with our easy-to-understand guide. See how they are built and how they work. Learn why they are important in computer science!"} />
                <HorizontalLine />
                <ArticleSectionHeader text={"Introduction"} />

                <ArticleParagraph text={"AVL trees are self-balancing binary search trees that were invented by Adelson-Velsky and Landis. In this blog post, we will explore the basic operations of AVL trees, including insertion and deletion. We will also discuss the four cases of rotation in the balancing algorithm: LL, RR, LR, and RL."} />

                <ArticleSectionHeader text={"What are AVL Trees?"} />
                <ArticleParagraph text={"AVL trees are essentially binary search trees with additional properties. The basic operations performed on AVL trees include insertion and deletion. These operations are similar to those performed on regular binary search trees, but with the added step of checking and maintaining the balance factor of the tree."} />

                <img src="https://upload.wikimedia.org/wikipedia/commons/3/33/AVL-Baum_13.svg" alt="AVL Tree" />



                <ArticleSectionHeader text={"Properties of AVL Trees"} />
                <ArticleParagraph text={"AVL trees have the following properties:"} />
                <ArticleList items={[
                    "Each node has a height value that is the maximum distance from the node to a leaf node.",
                    "The balance factor of a node is the difference between the height of the left subtree and the height of the right subtree.",
                    "The balance factor of each node must be -1, 0, or 1.",
                    "The height of the left and right subtrees of each node must differ by at most one."
                ]} />

                <ArticleSectionHeader text={"Basic Structure of an AVL Tree"} />

                <CodeSection code={`
            class Node {
                int data;
                int height;
                Node left;
                Node right;

                public Node(int data) {
                    this.data = data;
                    this.height = 1;
                }
            }
                    `} />

                <ArticleParagraph text={"The basic structure of an AVL tree consists of a Node class that contains the data value, height, and references to the left and right child nodes. The height of each node is calculated as the maximum distance from the node to a leaf node."} />



                <ArticleSectionHeader text={"Operations on AVL Trees"} />

                <ArticleParagraph text={"The basic operations performed on AVL trees include insertion and deletion. These operations are similar to those performed on regular binary search trees, but with the added step of checking and maintaining the balance factor of the tree."} />


                <ArticleSectionHeader text={"Helper Methods"} />
                <ArticleParagraph text={"The following helper methods are used to perform operations on AVL trees:"} />
                <ArticleList items={[
                    "height(Node node): Returns the height of a node.",
                    "updateHeight(Node node): Updates the height of a node.",
                    "balanceFactor(Node node): Returns the balance factor of a node."
                ]} />

                <CodeSection code={`
            private int height(Node node) {
                if (node == null) {
                        return -1;
                }
                return node.height;
            }

            private void updateHeight(Node node) {
                node.height = Math.max(height(node.left), height(node.right)) + 1;
            }

            private int balanceFactor(Node node) {
                return height(node.left) - height(node.right);
            }
                    `} />

                <ArticleSectionHeader text={"Insertion Operation"} />

                <ArticleParagraph text={"When inserting data into an AVL tree, the binary search tree property is followed. The left subtree must contain elements less than the root value, and the right subtree must contain all the greater elements. After each insertion, the balance factor of the tree is checked. If the balance factor exceeds 1, a balancing algorithm is applied to readjust the tree."} />

                <CodeSection code={`
            public Node insert(Node root, int data) {
                if (root == null) {
                    return new Node(data);
                }

                if (data < root.data) {
                    root.left = insert(root.left, data);
                } else if (data > root.data) {
                    root.right = insert(root.right, data);
                }

                updateHeight(root);

                int balanceFactor = balanceFactor(root);

                if (balanceFactor > 1) {
                    if (data < root.left.data) {
                        return rightRotate(root);
                    } else {
                        root.left = leftRotate(root.left);
                        return rightRotate(root);
                    }
                }

                if (balanceFactor < -1) {
                    if (data > root.right.data) {
                        return leftRotate(root);
                    } else {
                        root.right = rightRotate(root.right);
                        return leftRotate(root);
                    }
                }

                return root;
            }
                    `} />

                <ArticleSectionHeader text={"Deletion Operation"} />

                <ArticleParagraph text={"Deletion in AVL trees can occur in three different scenarios: deletion of a leaf node, deletion of a node with one child, and deletion of a node with two child nodes. In each scenario, the balance factor is checked and rotations are applied if necessary to maintain the balance of the tree."} />

                <ArticleSectionHeader text={"Deletion of a Leaf Node"} />
                <ArticleParagraph text={"If the node to be deleted is a leaf node, it is simply removed from the tree. The balance factor of the parent node is checked, and rotations are applied if necessary."} />

                <ArticleSectionHeader text={"Deletion of a Node with One Child"} />
                <ArticleParagraph text={"If the node to be deleted has one child, the child node is moved up to take the place of the deleted node. The balance factor of the parent node is checked, and rotations are applied if necessary."} />

                <ArticleSectionHeader text={"Deletion of a Node with Two Child Nodes"} />
                <ArticleParagraph text={"If the node to be deleted has two child nodes, the leftmost node in the right subtree is found. This node is then moved up to take the place of the deleted node. The balance factor of the parent node is checked, and rotations are applied if necessary."} />

                <CodeSection code={`
        public Node delete(Node root, int data) {
          if (root == null) {
              return null;
          }

          if (data < root.data) {
              root.left = delete(root.left, data);
          } else if (data > root.data) {
              root.right = delete(root.right, data);
          } else {
              if (root.left == null) {
                  return root.right;
              } else if (root.right == null) {
                  return root.left;
              } else {
                  Node minNode = findMin(root.right);
                  root.data = minNode.data;
                  root.right = delete(root.right, minNode.data);
              }
          }

          updateHeight(root);

          int balanceFactor = balanceFactor(root);

          if (balanceFactor > 1) {
              if (balanceFactor(root.left) >= 0) {
                  return rightRotate(root);
              } else {
                  root.left = leftRotate(root.left);
                  return rightRotate(root);
              }
          }

          if (balanceFactor < -1) {
              if (balanceFactor(root.right) <= 0) {
                  return leftRotate(root);
              } else {
                  root.right = rightRotate(root.right);
                  return leftRotate(root);
              }
          }

          return root;
        }
        `}
                />

                <ArticleSectionHeader text={"Balancing Algorithm"} />

                <ArticleParagraph text={"The balancing algorithm in AVL trees is used to maintain the balance of the tree after each insertion or deletion operation. There are four cases of rotation in the balancing algorithm: LL, RR, LR, and RL."} />

                <ArticleSectionHeader text={"LL Rotations"} />
                <ArticleParagraph text={"LL rotations are performed when a node is inserted into the right subtree, leading to an unbalanced tree. This rotation involves a single left rotation to make the tree balanced again. The unbalanced node becomes the left child, and the newly added node becomes the right child."} />

                <CodeSection code={`
        private Node leftRotate(Node node) {
            Node newRoot = node.right;
            node.right = newRoot.left;
            newRoot.left = node;
            updateHeight(node);
            updateHeight(newRoot);
            return newRoot;
        }
        `}
                />

                <ArticleSectionHeader text={"RR Rotations"} />

                <ArticleParagraph text={"RR rotations are performed when a node is inserted into the left subtree, leading to an unbalanced tree. This rotation involves a single right rotation to make the tree balanced again. The unbalanced node becomes the right child, and the newly added node becomes the left child."} />

                <CodeSection code={`
                
                private Node rightRotate(Node node) {
                    Node newRoot = node.left;
                    node.left = newRoot.right;
                    newRoot.right = node;
                    updateHeight(node);
                    updateHeight(newRoot);
                    return newRoot;
                }`}
                />

                <ArticleSectionHeader text={"LR Rotations"} />

                <ArticleParagraph text={"LR rotations are performed when a node is inserted into the right subtree of the left subtree. This rotation is a combination of a left rotation followed by a right rotation. Multiple steps are involved in carrying out this rotation, and it helps in maintaining the balance of the tree."} />

                <ArticleSectionHeader text={"RL Rotations"} />

                <ArticleParagraph text={"RL rotations are performed when a node is inserted into the left subtree of the right subtree. This rotation is a combination of a right rotation followed by a left rotation. Similar to LR rotations, multiple steps are involved in carrying out this rotation to maintain the balance of the tree."} />

                <ArticleTitle text={"Test Your Knowlege"} />
                <ArticleDescription text={"Test your knowledge of AVL trees with the following quick questions."} />

                <QuickQuestion text={"Develop an AVL Tree with these set of numbers: 5, 3, 7, 2, 4, 6, 8. What is the root?"} choices={["5", "3", "7", "2"]} answer={"5"} questionShown={true} />

                <QuickQuestion text={"Develop an AVL Tree with these set of numbers: 5, 3, 7, 2, 4, 6, 8. What are the leaves?"} choices={["2, 4, 6, 8", "5, 3, 7, 2", "2, 4, 6, 8, 7"]} answer={"2, 4, 6, 8"} questionShown={true} />

                <QuickQuestion text={"Develop an AVL Tree with these set of numbers: 5, 3, 7, 2, 4, 6, 8. What is height of the tree?"} choices={["1", "2", "3", "4"]} answer={"3"} questionShown={true} />

                <QuickQuestion text={"Develop an AVL Tree with these set of numbers: 5, 3, 7, 2, 4, 6, 8. What is depth of the node with value 4?"} choices={["1", "2", "3", "4"]} answer={"2"} questionShown={true} />

                <QuickQuestion text={"Develop an AVL Tree with these set of numbers: 5, 3, 7, 2, 4, 6, 8. What is the balance factor of the root?"} choices={["-1", "0", "1", "2"]} answer={"1"} questionShown={true} />

                <QuickQuestion text={"Develop an AVL Tree with these set of numbers: 5, 3, 7, 2, 4, 6, 8. What is the inorder traversal?"} choices={["2, 3, 4, 5, 6, 7, 8", "5, 3, 2, 4, 7, 6, 8", "2, 4, 3, 6, 8, 7, 5", "2, 4, 3, 6, 8, 7, 5"]} answer={"2, 3, 4, 5, 6, 7, 8"} questionShown={true} />

                <QuickQuestion text={"Develop an AVL Tree with these set of numbers: 5, 3, 7, 2, 4, 6, 8. What is the postorder traversal?"} choices={["2, 4, 3, 6, 8, 7, 5", "5, 3, 2, 4, 7, 6, 8", "2, 3, 4, 5, 6, 7, 8", "2, 4, 3, 6, 8, 7, 5"]} answer={"2, 4, 3, 6, 8, 7, 5"} questionShown={true} />

                <QuickQuestion text={"What type of rotation is needed when the balance factor of a node is -2 and the balance factor of its right child is -1?"} choices={["Left Rotation", "Right Rotation", "Left-Right Rotation", "Right-Left Rotation"]} answer={"Left Rotation"} questionShown={true} />

                <QuickQuestion text={"What type of rotation is needed when the balance factor of a node is 2 and the balance factor of its left child is 1?"} choices={["Left Rotation", "Right Rotation", "Left-Right Rotation", "Right-Left Rotation"]} answer={"Right Rotation"} questionShown={true} />

                <QuickQuestion text={"What type of rotation is needed when the balance factor of a node is -2 and the balance factor of its right child is 1?"} choices={["Left Rotation", "Right Rotation", "Left-Right Rotation", "Right-Left Rotation"]} answer={"Right-Left Rotation"} questionShown={true} />

                <QuickQuestion text={"What type of rotation is needed when the balance factor of a node is 2 and the balance factor of its left child is -1?"} choices={["Left Rotation", "Right Rotation", "Left-Right Rotation", "Right-Left Rotation"]} answer={"Left-Right Rotation"} questionShown={true} />



                {/* {finished && <h2>You've Finished!</h2>}
               
                {finished && <button className="nextSectionButton" onClick={() => window.location.href = "/create-quiz/"}>
                    Try a AVL Quiz
                </button>} */}
            </div >
        </>
    );
};

export default AVLArticle;

import React, {useState} from 'react';
import './quickQuestion.css';
const QuickQuestion = ({ text, choices, answer, questionShown}) => {
    const [selected, setSelected] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [correct, setCorrect] = useState(false);
    const [incorrect, setIncorrect] = useState(false);
    const [showAnswer, setShowAnswer] = useState(false);

    if (!questionShown) {
        return null;
    }
    const submit = () => {
        setSubmitted(true);
        if (selected === answer) {
            setCorrect(true);
        } else {
            setIncorrect(true);
        }
    }

    const show = () => {
        setShowAnswer(true);
    }

    if (submitted) {
        return (
            <div className='quiz-solvable'>
                <h1 className='quiz-solvable-header'>{text}</h1>
                <ul className='quiz-solvable-choices'>
                    {choices.map((choice) => (
                        <li className='quiz-solvable-choice'>{choice}</li>
                    ))}
                </ul>
                <p>Correct answer: {answer}</p>
                <p>You chose: {selected}</p>
                <p>{correct ? "Correct!" : "Incorrect!"}</p>
            </div>
        );
    }

    return (
        <div className='quiz-solvable'>
            <p style={{opacity:0.5, fontSize:'14px'}}>Quick Questions to test your knowledge!</p>
            <h1 className='quiz-solvable-header'>{text}</h1>
            <ul className='quiz-solvable-choices'>
                {choices.map((choice) => (
                    <li className='choice'>
                        <input type="radio" name={text} value={choice} onChange={(e) => setSelected(e.target.value)} />
                        {choice}
                    </li>
                ))}
            </ul>
            <button className='submitButton' onClick={submit}>Submit</button>
            <button className='showAnswerButton' onClick={show}>Show Answer</button>
            {showAnswer && <p>Answer: {answer}</p>}
        </div>
    );
};

export default QuickQuestion;

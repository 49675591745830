import React from 'react';
import "../styles/under_construction.css";
import Navbar from './articleSysemComponents/navbar';
const StillWorkingOn = () => {
    return (
        <>
            <Navbar />
            <div class="main-wrapper">
                <div class="signboard-wrapper">
                    <div class="signboard">Under Construction</div>
                    <div class="string"></div>
                    <div class="pin pin1"></div>
                    <div class="pin pin2"></div>
                    <div class="pin pin3"></div>
                </div>
            </div>
            </>
    )
};

export default StillWorkingOn;

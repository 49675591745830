import React from 'react';
import './css/article_complete.css';
const ArticleDescription = ({ text }) => {
    return (
        <div className="article_description_component">
            <p>{text}</p>
        </div>
    );
};

export default ArticleDescription;

import React from 'react';
import './css/article_complete.css';
const ArticleParagraph = ({ text }) => {
    return (
        <div className="article_text_pragraph">
            <p>{text}</p>
        </div>
    );
};

export default ArticleParagraph;

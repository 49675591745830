import React from 'react';
import "../styles/results.css";
const ResultsPage = ({ score, num_questions }) => {
    // Calculate the percentage score
    const percentage = (score / num_questions) * 100;

    // Define a function to determine the result message based on the percentage
    const getResultMessage = () => {
        if (percentage === 100) {
            return "Congratulations! You got all the questions right!";
        } else if (percentage >= 80) {
            return "Great job! You did well!";
        } else if (percentage >= 60) {
            return "Not bad! You passed!";
        } else {
            return "You can do better. Keep practicing!";
        }
    };

    return (
        <div className="results-container">
            <h2 className="results-heading">Quiz Results</h2>
            <p className="results-score">Your Score: {score} out of {num_questions}</p>
            <p className="results-score">Percentage: {percentage}%</p>
            <p className="results-message">{getResultMessage()}</p>
        </div>
    );
};

export default ResultsPage;

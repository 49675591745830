import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/articleSysemComponents/navbar';
import QuickQuestion from "../components/articleSysemComponents/quickQuestion";


import ArticleTitle from "../components/articleSysemComponents/ArticleTitle";
import HorizontalLine from "../components/articleSysemComponents/HorizontalLine";
import ArticleDescription from "../components/articleSysemComponents/ArticleDescription";
import ArticleSectionHeader from "../components/articleSysemComponents/ArticleSectionHeader";
import ArticleParagraph from "../components/articleSysemComponents/ArticleParagraph";
import ArticleList from "../components/articleSysemComponents/ArticleList";
import CodeSection from "../components/articleSysemComponents/CodeSection";

// currentSectionIndex\s*===\s*\d+

const LinkedListArticle = () => {

  return (
    <>
      <Helmet>
        <title>Linked List - Understanding a Basic Data Structure</title>
        <meta name="description" content="Linked lists are a dynamic and versatile data structure that can easily adapt to changes in size. Unlike arrays, inserting and deleting elements from a linked list is a straightforward process. This is because all we need to do is adjust the pointers of the preceding and succeeding elements." />
      </Helmet>

      <Navbar title={'Linked List'} />
      <div className="article_container">
        <ArticleTitle text={"Understanding Linked Lists"} />
        <HorizontalLine />
        <ArticleDescription
          text={
            "Dive into the world of Linked Lists, a fundamental data structure in computer science, and explore its various types and operations."
          }
        />
        <HorizontalLine />
        <ArticleSectionHeader text={"Introduction to Linked Lists"} />
        <ArticleParagraph
          text={
            "A Linked List is a linear data structure that consists of a sequence of elements, called nodes, where each node points to the next node in the sequence. Unlike arrays, linked lists do not have a fixed size, allowing for dynamic memory allocation."
          }
        />
        <ArticleParagraph
          text={
            "Key characteristics of Linked Lists include:"
          }
        />
        <ArticleList
          items={[
            "Dynamic size - can grow or shrink during runtime.",
            "Each node contains data and a reference (or link) to the next node.",
            "Memory allocation is done as nodes are added, allowing for efficient use of memory.",
            "Insertion and deletion operations are generally faster than arrays.",
          ]}
        />
        <ArticleSectionHeader text={"Types of Linked Lists"} />
        <ArticleParagraph
          text={
            "There are several types of Linked Lists, each with its own characteristics:"
          }
        />
        <ArticleList
          items={[
            "Singly Linked List: Each node points to the next node in the sequence.",
            "Doubly Linked List: Each node points to both the next and previous nodes.",
            "Circular Linked List: Last node points back to the first node, forming a circle.",
          ]}
        />
        <ArticleSectionHeader text={"Basic Structure of a Node"} />
        <CodeSection
          code={`
        class Node {
            int data;
            Node next;

            public Node(int data) {
                this.data = data;
                this.next = null;
            }
        }
        `}
        />
        <ArticleParagraph
          text={
            "The structure of a node in a Linked List typically includes a data field and a reference (next) to the next node. Here, 'data' represents the value stored in the node, while 'next' is a pointer to the next node in the sequence."
          }
        />
        <ArticleSectionHeader text={"Operations on Linked Lists"} />
        <ArticleParagraph
          text={
            "Common operations performed on Linked Lists include insertion, deletion, traversal, and searching."
          }
        />
        <ArticleSectionHeader text={"Insertion Operation"} />
        <ArticleParagraph
          text={
            "Inserting a new node into a Linked List involves updating the pointers of the existing nodes to accommodate the new node."
          }
        />
        <ArticleSectionHeader text={"Deletion Operation"} />
        <ArticleParagraph
          text={
            "Deleting a node from a Linked List requires updating the pointers of neighboring nodes to bypass the node being deleted."
          }
        />
        <ArticleSectionHeader text={"Traversal"} />
        <ArticleParagraph
          text={
            "Traversing a Linked List means visiting each node sequentially from the beginning to the end."
          }
        />
        <ArticleSectionHeader text={"Searching"} />
        <ArticleParagraph
          text={
            "Searching for a specific value in a Linked List involves iterating through the nodes until the target value is found or reaching the end of the list."
          }
        />
        <ArticleSectionHeader text={"Advantages of Linked Lists"} />
        <ArticleList
          items={[
            "Dynamic size: Linked Lists can grow or shrink as needed.",
            "Efficient insertion and deletion: Operations can be faster than arrays, especially for large lists.",
            "No need for contiguous memory: Nodes can be scattered in memory.",
            "Support for different types: Singly, doubly, and circular Linked Lists offer flexibility.",
          ]}
        />
        <ArticleSectionHeader text={"Disadvantages of Linked Lists"} />
        <ArticleList
          items={[
            "Slower access time: Searching for an element is slower than arrays due to sequential traversal.",
            "Extra memory: Each node requires additional memory for the next pointer.",
            "No random access: Direct access to elements by index is not possible; traversal is required.",
          ]}
        />
        <ArticleSectionHeader text={"Conclusion"} />
        <ArticleParagraph
          text={
            "Linked Lists are versatile and efficient data structures that offer dynamic memory allocation and fast insertion/deletion operations. Understanding the various types of Linked Lists and their operations is essential for algorithm design and optimization."
          }
        />



        <QuickQuestion text={"What is a linked list?"} choices={["A type of array", "A data structure consisting of nodes", "A type of function", "A type of loop"]} answer={"A data structure consisting of nodes"} questionShown={true} />

        <QuickQuestion text={"How does a linked list differ from an array?"} choices={["They are the same", "Linked list elements are not stored at contiguous memory locations", "Array is a low-level data structure", "Linked list is a primitive data type"]} answer={"Linked list elements are not stored at contiguous memory locations"} questionShown={true} />

        <QuickQuestion text={"What are the types of linked lists?"} choices={["Singly, Doubly, and Circular", "Linear, Binary, and Ternary", "Static, Dynamic, and Hybrid", "None of the above"]} answer={"Singly, Doubly, and Circular"} questionShown={true} />

        <QuickQuestion text={"How do you insert a node at the beginning of a linked list?"} choices={["By reallocating memory", "By creating a new node and pointing it to the first node", "By using a loop", "None of the above"]} answer={"By creating a new node and pointing it to the first node"} questionShown={true} />

        <QuickQuestion text={"What is a doubly linked list?"} choices={["A list with two nodes", "A list where each node points to the next node and the previous node", "A list that can only be traversed in two directions", "None of the above"]} answer={"A list where each node points to the next node and the previous node"} questionShown={true} />

        <QuickQuestion text={"Consider a singly linked list with the values 1, 2, 3, 4, 5. What is the value of the third node in the list after it has been reversed?"} choices={["1", "2", "3", "4", "5"]} answer={"3"} questionShown={true} />

        <QuickQuestion text={"You have a linked list with the values 1, 2, 3, 4, 5, and a pointer to the third node. What is the value of the first node after inserting a node with the value 0 before the given node?"} choices={["0", "1", "2", "3", "4", "5"]} answer={"0"} questionShown={true} />

        <QuickQuestion text={"Extend your linked list implementation to support inserting a node with the value 6 at the end. What is the value of the last node after this operation?"} choices={["0", "1", "2", "3", "4", "5", "6"]} answer={"6"} questionShown={true} />

        <QuickQuestion text={"You are tasked with deleting the third node from a linked list with values 1, 2, 3, 4, 5. What is the value of the new third node after the deletion?"} choices={["0", "1", "2", "3", "4", "5", "6"]} answer={"4"} questionShown={true} />

        <QuickQuestion text={"Consider a doubly linked list with the values 1, 2, 3, 4, 5. What is the value of the third node in the list?"} choices={["1", "2", "3", "4", "5"]} answer={"3"} questionShown={true} />


      </div>
    </>
  );
};

export default LinkedListArticle;

import Header from "./articleSysemComponents/header";
import Paragraph from "./articleSysemComponents/paragraph";
import Image from "./articleSysemComponents/image";
import Code from "./articleSysemComponents/code";


import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/default-highlight';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';


class ArticleSystem {
    constructor() {
        this.article = [];

    }

    addHeader(text) {
        this.article.push({ type: 'header', text });
    }

    addParagraph(text) {
        this.article.push({ type: 'paragraph', text });
    }

    addImage(image) {
        this.article.push(image);
    }

    addCode(text) {
        this.article.push({ type: 'code', text });
    }

    getArticle() {

        return this.article.map((component, index) => {

            if (component.type === 'header') {
                const headerStyle = {
                    fontFamily: "SoleilBold",
                    fontSize: "2rem",
                    fontWeight: "bold",
                    color: "black",
                    underline: "true",

                };
                return <h1 key={index} style={headerStyle}>{component.text}</h1>;
            } else if (component.type === 'paragraph') {
                const paragraphStyle = {
                    fontFamily: "SoleilRegular",
                    fontSize: '1.2rem',
                    color: 'black',
                };
                return <p key={index} style={paragraphStyle}>{component.text}</p>;
            } else if (component.type === 'code') {
                return (
                    <SyntaxHighlighter key={index} language="java" style={docco}>
                        {component.text}
                    </SyntaxHighlighter>
                );
            } else if (component.type === 'image') {
                const imageStyle = {
                    width: '100%',
                    height: '100%',
                    margin: '1rem 0',
                };

                return (
                    <img key={index} style={imageStyle} src={component.source} alt="article_image" />
                );
            }

            return null;
        });
    }


    addSection(section) {
        this.article.push(section);
    }
}

export default ArticleSystem;
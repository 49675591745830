import React from 'react';

const Pricing = () => {
    return (
        <div>
            <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
            <stripe-pricing-table pricing-table-id="prctbl_1Owa88L1ZHgb3YYI6K60Ixt0"
                publishable-key="pk_live_51OOsaLL1ZHgb3YYImATomLBGVgpxSyBNQP0csRIQgq3MYu5dur7b2AMQoXpmbSfqgvKUx2XjJoPh1DXcBRM9VmZa00UEvbUgsF">
            </stripe-pricing-table>
        </div>
    );
};

export default Pricing;
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/articleSysemComponents/navbar';
import QuickQuestion from '../components/articleSysemComponents/quickQuestion';

import ArticleTitle from "../components/articleSysemComponents/ArticleTitle";
import HorizontalLine from "../components/articleSysemComponents/HorizontalLine";
import ArticleDescription from "../components/articleSysemComponents/ArticleDescription";
import ArticleSectionHeader from "../components/articleSysemComponents/ArticleSectionHeader";
import ArticleParagraph from "../components/articleSysemComponents/ArticleParagraph";
import ArticleList from "../components/articleSysemComponents/ArticleList";
import CodeSection from "../components/articleSysemComponents/CodeSection";


const QueuesArticle = () => {

    return (
        <>
            <Helmet>
                <title>Queues Data Structure</title>
                <meta name="description" content="Discover the fundamentals of the Queue data structure, its operations, and practical applications. Learn how queues work, explore different types of queues, and dive into examples of queue implementations in programming languages like Java, Python, and more." />
                <meta name="keywords" content="Data Structures, Algorithms, Queue, FIFO, First In First Out, Programming, Java, Python, C++, JavaScript, Code, Implementation, Operations, Applications, Computer Science" />
            </Helmet>


            <Navbar title={'Queues'} />

            <div className="article_container">
                <ArticleTitle text={"Understanding Queues: A Fundamental Data Structure"} />
                <HorizontalLine />
                <ArticleDescription
                    text={
                        "Explore the concept of Queues, a versatile data structure with applications in computer science, and learn about its properties, operations, and implementations."
                    }
                />
                <HorizontalLine />
                <ArticleSectionHeader text={"Introduction to Queues"} />
                <ArticleParagraph
                    text={
                        "A Queue is a linear data structure that follows the First-In-First-Out (FIFO) principle, where the first element added to the queue is the first one to be removed. It resembles a queue of people waiting in line."
                    }
                />
                <ArticleParagraph
                    text={
                        "Key characteristics of Queues include:"
                    }
                />
                <ArticleList
                    items={[
                        "Elements are added at the 'rear' (enqueue) and removed from the 'front' (dequeue).",
                        "Only the front element is accessible for removal.",
                        "Operations include enqueue (addition) and dequeue (removal).",
                        "Queues can be implemented using arrays or linked lists.",
                    ]}
                />
                <ArticleSectionHeader text={"Basic Structure of a Queue"} />
                <ArticleParagraph
                    text={
                        "A queue can be visualized as a horizontal arrangement of elements, where elements are added at one end (rear) and removed from the other end (front)."
                    }
                />
                <ArticleSectionHeader text={"Operations on Queues"} />
                <ArticleParagraph
                    text={
                        "Common operations performed on Queues include enqueue, dequeue, peek, and isEmpty."
                    }
                />
                <ArticleSectionHeader text={"Enqueue Operation"} />
                <ArticleParagraph
                    text={
                        "Enqueueing (or adding) an element to a queue involves placing the new element at the rear of the queue."
                    }
                />
                <ArticleSectionHeader text={"Dequeue Operation"} />
                <ArticleParagraph
                    text={
                        "Dequeuing (or removing) an element from a queue involves taking out the element at the front of the queue."
                    }
                />
                <ArticleSectionHeader text={"Peek Operation"} />
                <ArticleParagraph
                    text={
                        "Peek allows you to view the front element of the queue without removing it."
                    }
                />
                <ArticleSectionHeader text={"isEmpty Operation"} />
                <ArticleParagraph
                    text={
                        "Checks if the queue is empty or not. Returns true if the queue is empty, false otherwise."
                    }
                />
                <ArticleSectionHeader text={"Applications of Queues"} />
                <ArticleList
                    items={[
                        "Task Scheduling: Used in operating systems to manage tasks and processes.",
                        "Breadth-First Search (BFS): A graph traversal algorithm that utilizes queues.",
                        "Printer Queue: Managing print jobs in computer systems.",
                        "Call Center Systems: Handling incoming calls in a sequential manner.",
                        "Buffering in Networking: Storing and managing data packets in network communication.",
                    ]}
                />
                <ArticleSectionHeader text={"Types of Queues"} />
                <ArticleList
                    items={[
                        "Linear Queue: Simplest form of queue with a fixed size.",
                        "Circular Queue: Circular arrangement of elements for efficient use of space.",
                        "Priority Queue: Queue where elements are removed based on priority.",
                        "Double-Ended Queue (Deque): Supports insertion and deletion at both ends.",
                    ]}
                />
                <ArticleSectionHeader text={"Advantages of Queues"} />
                <ArticleList
                    items={[
                        "Efficient for managing data flow in a sequential manner.",
                        "Facilitates task scheduling and process management.",
                        "Enables the implementation of various algorithms such as BFS.",
                        "Supports multiple types for diverse application scenarios.",
                    ]}
                />
                <ArticleSectionHeader text={"Disadvantages of Queues"} />
                <ArticleList
                    items={[
                        "Limited in size based on available memory.",
                        "Access to non-front elements requires dequeuing.",
                        "Not efficient for searching or accessing random elements.",
                    ]}
                />
                <ArticleSectionHeader text={"Conclusion"} />
                <ArticleParagraph
                    text={
                        "Queues are foundational data structures with widespread applications in computer science and software development. Understanding the principles and operations of queues is essential for designing efficient algorithms and systems."
                    }
                />
                <QuickQuestion text={"What is a queue?"} choices={["A type of array", "A data structure that follows the FIFO principle", "A type of function", "A type of loop"]} answer={"A data structure that follows the FIFO principle"} questionShown={true} />

                <QuickQuestion text={"What does FIFO stand for in the context of a queue?"} choices={["First In First Out", "First In Final Out", "Fast In Fast Out", "Fast In Final Out"]} answer={"First In First Out"} questionShown={true} />

                <QuickQuestion text={"What are the basic operations of a queue?"} choices={["Enqueue, Dequeue, Peek, and IsEmpty", "Insert, Delete, Update, and Search", "Add, Remove, Check, and IsFull", "None of the above"]} answer={"Enqueue, Dequeue, Peek, and IsEmpty"} questionShown={true} />

                <QuickQuestion text={"What does the Enqueue operation do in a queue?"} choices={["Removes an element from the queue", "Adds an element to the queue", "Checks the front element of the queue", "Checks if the queue is empty"]} answer={"Adds an element to the queue"} questionShown={true} />

                <QuickQuestion text={"What does the Dequeue operation do in a queue?"} choices={["Removes an element from the queue", "Adds an element to the queue", "Checks the front element of the queue", "Checks if the queue is empty"]} answer={"Removes an element from the queue"} questionShown={true} />

                <QuickQuestion
                    text={"What is a circular queue?"}
                    choices={[
                        "A queue that allows elements to be added and removed only from one end",
                        "A queue where elements are stored in a circular arrangement",
                        "A queue that supports priority-based enqueue and dequeue operations",
                        "A queue that allows elements to be added and removed in random order"
                    ]}
                    answer={"A queue where elements are stored in a circular arrangement"}
                    questionShown={true}
                />

                <QuickQuestion
                    text={"Explain the concept of a priority queue."}
                    choices={[
                        "A queue where elements are stored based on their insertion order",
                        "A queue that allows elements to be added and removed based on a priority level",
                        "A queue that sorts elements in ascending order before enqueue",
                        "A queue that shuffles elements randomly before dequeue"
                    ]}
                    answer={"A queue that allows elements to be added and removed based on a priority level"}
                    questionShown={true}
                />

                <QuickQuestion
                    text={"What is the advantage of using a double-ended queue (deque)?"}
                    choices={[
                        "It allows elements to be added and removed from both ends efficiently",
                        "It has a fixed size, ensuring constant time operations",
                        "It can only store elements of the same data type",
                        "It guarantees elements are always sorted after each operation"
                    ]}
                    answer={"It allows elements to be added and removed from both ends efficiently"}
                    questionShown={true}
                />

                <QuickQuestion
                    text={"How does a queue differ from a stack?"}
                    choices={[
                        "A queue follows the FIFO principle, while a stack follows LIFO",
                        "A queue allows random access to its elements, while a stack does not",
                        "A queue has a fixed size, while a stack can dynamically resize",
                        "A queue allows elements to be added and removed at any position, while a stack does not"
                    ]}
                    answer={"A queue follows the FIFO principle, while a stack follows LIFO"}
                    questionShown={true}
                />

                <QuickQuestion
                    text={"In what scenarios would a priority queue be more efficient than a regular queue?"}
                    choices={[
                        "When elements are always added in the order they need to be processed",
                        "When elements have different levels of urgency or importance",
                        "When elements need to be processed in the order they were added",
                        "When elements are added and removed frequently"
                    ]}
                    answer={"When elements have different levels of urgency or importance"}
                    questionShown={true}
                />

            </div>
        </>
    );
};

export default QueuesArticle;

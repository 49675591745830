// src/App.js
import React from 'react';
import "../styles/quizzes.css";
import axios from 'axios';
import { useState } from 'react';
import Navbar from './articleSysemComponents/navbar';
const quizData = [
    // {
    //     name: 'Practice Quiz 1B',
    //     description: 'This quiz covers key concepts such as data types, loops, arrays, and object-oriented principles. Answer questions on type casting, conditional statements, and memory management.',
    //     numQuestions: 32,
    // },
    // {
    //     name: 'Quiz 2',
    //     description: 'Description for Quiz 2',
    //     numQuestions: 15,
    // },
    // {
    //     name: 'Quiz 3',
    //     description: 'Description for Quiz 3',
    //     numQuestions: 20,
    // },
    // {
    //     name: 'Quiz 4',
    //     description: 'Description for Quiz 4',
    //     numQuestions: 12,
    // },
    // {
    //     name: 'Quiz 5',
    //     description: 'Description for Quiz 5',
    //     numQuestions: 8,
    // },
];


function Quizzes() {
    function customBtoa(str) {
        // Use encodeURIComponent to handle characters within Latin1 range
        var encoded = encodeURIComponent(str);
      
        // Replace characters outside the Latin1 range manually
        encoded = encoded.replace(/%([0-9A-F]{2})/g, function(match, p1) {
          var charCode = parseInt(p1, 16);
          return String.fromCharCode(charCode);
        });
      
        return encoded;
      }

      
    function startQuiz(name) {
        // remove the "quiz" string and keep the number
        var quiz_number = name.replace("Quiz ", "");
        console.log(typeof (quiz_number));
        console.log(quiz_number);
        // conver the quiz number to an integer
        quiz_number = parseInt(quiz_number);
        // http://localhost:5000/

        // axios.get('https://dsa-practice.onrender.com/getRealQuiz/' + quiz_number)
        // .then(function (response) {
        
        //     var final_quiz = response.data.quiz_data;

        //     var quiz_id = response.data.quizID;
        //     localStorage.setItem(quiz_id, final_quiz);
        //     window.location.href = "/quiz/" + quiz_id;
        // })
        // .catch(function (error) {
        //     console.log(error);
        // });
    }
    return (
        <>
            {/* <h1>Still Working on them</h1> */}
            <Navbar title={"Quizzes"} />
            <div className="app-container" style={{ height: "100vh" }}>
                
                {/* <h1 className='quizzes-title'>Quiz List</h1> */}
                <table className="quiz-table">
                    <thead>
                        <tr>
                            <th className="quiz-name">Name</th>
                            <th className="quiz-description">Description</th>
                            <th className="quiz-num-questions">Number of Questions</th>
                            <th className="quiz-action">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {quizData.map((quiz, index) => (
                            <tr key={index}>
                                <td className="quiz-name">{quiz.name}</td>
                                <td className="quiz-description">{quiz.description}</td>
                                <td className="quiz-num-questions">{quiz.numQuestions}</td>
                                <td className="quiz-action">
                                    <button className="start-quiz" onClick={() => startQuiz(quiz.name)}>
                                        Start Quiz
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

function startQuiz(quizName) {
    // Add your logic to handle quiz start here
    console.log(`Starting quiz: ${quizName}`);
}

export default Quizzes;

import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "../styles/home.css";
import ReactGA from "react-ga4";
import logo from "../images/logo_white.png";
import people from "../images/ppl.png";
// import aGuyWorking from "../images/guyWorking.gif";

import { datastructres, algorithms } from "../tools/varibles";
import AdsComponent from "./adsComponent";

import Typewriter from 'typewriter-effect/dist/core';
const Home = () => {

    const [isCollapsed, setIsCollapsed] = useState(true);
    var [tab, setTab] = useState("data_structures");

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };


    useEffect(() => {
        var app = document.getElementById('typewriter');

        var typewriter = new Typewriter(app, {
            strings: ['debugging', 'problem solving', 'coding', 'data structures', 'algorithms'],
            autoStart: true,
            loop: true,
        });

        var header = document.querySelector("header");
        if (header) {
            var sticky = header.offsetTop;
            window.onscroll = function () { myFunction(header, sticky) };
        }

        function myFunction(header, sticky) {
            if (window.pageYOffset > sticky) {
                header.classList.add("sticky");
            } else {
                header.classList.remove("sticky");
            }
        }

    }, [])



    ReactGA.send({ hitType: "pageview", page: "/" });
    return (
        <>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3022151680297278"
                crossorigin="anonymous"></script>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3022151680297278"
                crossorigin="anonymous"></script>
            <div>
                <title>DSA Practice</title>

                <header class="main-header">
                    <div class="logo">
                        <img src={logo} className="logo_image" alt="logo" id="logo_image" />
                        <h1 class="site-name">DSA Practice</h1>
                    </div>
                    <div class="site-info">
                      
                        <nav class="main-nav">
                            <ul>
                                {/* <li><a href="/pricing">Pricing</a></li>
                                <li><a href="#">Projects</a></li>
                                <li><a href="#">Learn Now</a></li> */}
                                <button className="nav-button" to="/create-quiz">Take Quiz</button>
                            </ul>
                        </nav>
                    </div>
                </header>

                <div className="hero">
                    <div className="header">
                        <div className="container">
                            <div style={{ height: '530px' }}>

                                <h1 className="hero-header">The best way to learn <br /> <span id="typewriter"></span> </h1>
                                <p className="hero-subtitle">
                                    Master coding with hands-on practice in data structures, algorithms, and more. Learn from trusted universities and enhance your skills for a successful software development career.
                                </p>
                                <a className="hero-button" href="#data_structures">Start Practicing</a>                                
                            </div>
                        </div>
                    </div>
                </div>

                <img src={people} className="hero-image" alt="logo" height={400} width={650} />

                <div className="features">
                    <div className="feature">
                        <h2 className="feature-title">20+</h2>
                        <p className="feature-description">
                            Full-length tutorials!
                        </p>
                    </div>
                    <div className="feature highlight">
                        <h2 className="feature-title">250+</h2>
                        <p className="feature-description">
                            Happy users!
                        </p>
                    </div>
                    <div className="feature">
                        <h2 className="feature-title">10+</h2>
                        <p className="feature-description">
                            Amazing universities!
                        </p>
                    </div>
                </div>


                <div className="transition">
                    <div className="transition_title">
                        <h1>All the skills you need in one place</h1>
                    </div>
                    <div className="transition_content">
                        <p>From critical workplace skills to technical topics, our catalog supports well-rounded professional development.</p>
                    </div>
                </div>

                        
                <div className="options">
                    <div className="option">
                        <button className="option_title" onClick={() => setTab("data_structures")}>Data Structures</button>
                    </div>
                    <div className="option">
                        <button className="option_title" onClick={() => setTab("algorithms")}>Algorithms</button>
                    </div>
                    <div className="option">
                        <button className="option_title" onClick={() => setTab("problem_solving")}>Problem Solving</button>
                    </div>
                    <div className="option">
                        <button className="option_title" onClick={() => setTab("debugging")}>Debugging</button>
                    </div>
                </div>
                
                
                {/* if we are on the data_structures tab */}
                {tab === "data_structures" && (

                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.1, duration: 1 }}
                    className="section"
                    id="data_structures"
                >
                   {/* <div className="section_title" id="data_structures">Data Structues</div> */}
                    <div className="parent">

                        {datastructres.map((datastructure) => (
                            <a style={{ position: datastructure.position, textDecoration: 'none' }}>

                                <div className="child">
                                    <img src={datastructure.image} alt={datastructure.name} />
                                    <div className="algo_name" style={{ fontWeight: 'bold' }}>{datastructure.name}</div>
                                    <div className="algo_description">{datastructure.description}</div>
                                    <motion.div
                                        whileHover={{ scale: 1.07 }}
                                        whileTap={{ scale: 0.9 }}
                                    >
                                        <a href={datastructure.link} className="start_lesson">Start Lesson</a></motion.div>
                                </div>

                            </a>
                        ))}
                    </div>
                </motion.div>
                )}
                <AdsComponent dataAdSlot="1956905885" />

                {/* if we are on the algorithms tab */}
                {tab === "algorithms" && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.1, duration: 1 }}
                    className="section"
                >
                    {/* <div className="section_title">Algorithms</div> */}
                    <div className="parent">
                        {algorithms.map((algorithm) => (
                            <a style={{ position: algorithm.position }}>

                                <div className="child">
                                    <img src={algorithm.image} alt={algorithm.name} />
                                    <div className="algo_name">{algorithm.name}</div>
                                    <div className="algo_description">{algorithm.description}</div>
                                    <motion.div
                                        whileHover={{ scale: 1.07 }}
                                        whileTap={{ scale: 0.9 }}
                                    >
                                        <a href={algorithm.link} className="start_lesson">Start Lesson</a></motion.div>
                                </div>

                            </a>
                        ))}
                    </div>
                </motion.div>
                )}
            </div >
{/* 
            <div class="binary-wall">
                <div class="binary-section-title">Over 300+ Practice MCQ Questions!</div>
                <div class="binary-numbers">
                    <div class="binary-number">01101110 01110011 00100000 01101101 01111001 00100000 01100100 </div>
                    <div class="binary-number">01100001 01110010 01101011 01101110 </div>
                
                    <div class="binary-number">01101000 01110100 00101110 00100010 00001010 11100010 </div>
                    <div class="binary-number"> 10000000 10010100 01010000 01110011 01100001 01101100  </div>
                    <div class="binary-number"> 01101101 01110011 00100000 00110001 00111000 00111010 00110010 00111000 </div>
                </div>
            </div> */}


            {/* <AdsComponent dataAdSlot="1956905885" /> */}
        </>
    );
}

export default Home;


import './App.css';
import { Routes, Route } from 'react-router-dom';
import CreateQuiz from './components/create_quiz';
import Home from './components/home';
import Quizzes from './components/quizzes';
import Quiz from './components/quiz';
import LoadingScreen from './components/loading_screen';
import { useState, useEffect } from 'react';
import Article from './components/article';
import Popup from './components/popup';
import PrivacyPolicy from './components/privacyPolicy';
import CookiePolicy from './components/cookiePolicy';
import TermsOfUse from './components/termsOfUse';
import Footer from './components/footer';
import StillWorkingOn from './components/still_working_on';
import StudyGuides from './components/study_guides';

import BSTArticle from './articles/bst_article_new';
import AVLArticle from './articles/avl_article_new';
import HeapsArticle from './articles/heaps_article_new';
import LinkedListArticle from './articles/linked_list_article_new';
import StacksArticle from './articles/stacks_article_new';
import QueuesArticle from './articles/queue_article_new';
import HashtableArticle from './articles/hashtables_article_new';


import Pricing from './components/pricing';

import Login from './components/auth/login';
function App() {

  const [showPopup, setShowPopup] = useState(!document.cookie.includes('visited'));

  const handlePopupClose = () => {
    setShowPopup(false);
  };


  return (
    <>
      <head>

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="google-site-verification" content="Vzht5yO0hd0TqYHXEPmlZzvWhNHqLkqYSocUdNq6ag4" />
        {/* <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3022151680297278"
          crossorigin="anonymous"></script> */}
        <meta name="google-adsense-account" content="ca-pub-3022151680297278" />
      </head>
      
      {/* {showPopup && <Popup onClose={handlePopupClose} />} */}
      <Routes>
        <Route path="/" element={<Home />} />
        
        <Route path="/login" element={<Login />} />

        <Route path="/pricing" element={<Pricing />} />

        <Route path="/quizzes" element={<Quizzes />} />
        <Route path="/create-quiz/" element={<CreateQuiz />} />
        <Route path="/quiz/:quizID" element={<Quiz />} />
        <Route path="/loading" element={<LoadingScreen />} />
        {/* <Route path="/study-guides" element={<StudyGuides directoryPath={"./study_guides/"} />} /> */}

        <Route path="/article/under_construction" element={< StillWorkingOn />} />
        <Route path="/article/binary-search-tree" element={< BSTArticle/>} />
        <Route path="/article/avl-tree" element={< AVLArticle/>} />
        <Route path="/article/heaps" element={< HeapsArticle/>} />
        <Route path="/article/linked-list" element={< LinkedListArticle/>} />
        <Route path="/article/stack" element={< StacksArticle/>} />
        <Route path="/article/queue" element={< QueuesArticle/>} />
        <Route path="/article/hashtables" element={< HashtableArticle/>} />

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;

import React, { useState } from 'react';
import '../styles/practiceMaterialsSection.css';
import FlashCard from '../images/flashcards.png'
function PracticeMaterialSection({ flashcard_url, quiz_type }) {
    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return (

        <div className='practice-material-section'>
            <div style={{ position: 'fixed', bottom: 20, right: 20 }}>
                <button onClick={toggleExpanded} className='expand_section_button'>
                    {expanded ? <span>&lt;</span> : <span>&gt;</span>} Practice Material
                </button>
                {expanded && (
                    <div style={{ position: 'fixed', bottom: 70, right: 20 }} className='practice_material_buttons'>
                        <div className='section'>
                            <div className='flashcard'>
                                <img src={FlashCard} alt='flashcard' />
                    
                                <a href={flashcard_url} className="button_title" target='_blank' rel='noopener noreferrer'>
                                    Flashcards
                                </a>
                            </div>
                        </div>
                        <div className='section'>
                            <div className='flashcard'>
                                <button>Button 2</button>
                            </div>
                        </div>
                        <div className='section'>
                            <div className='flashcard'>
                                <button>Button 3</button>
                           </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default PracticeMaterialSection;

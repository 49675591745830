import React from "react";
import "./css/article_complete.css";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

const CodeSection = ({code}) => {
    return (
        <div className="code_section">
            <SyntaxHighlighter language="java" style={docco}>
                {code}
            </SyntaxHighlighter>
        </div>
    );
}

export default CodeSection;
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/articleSysemComponents/navbar';
import QuickQuestion from '../components/articleSysemComponents/quickQuestion';

import ArticleTitle from "../components/articleSysemComponents/ArticleTitle";
import HorizontalLine from "../components/articleSysemComponents/HorizontalLine";
import ArticleDescription from "../components/articleSysemComponents/ArticleDescription";
import ArticleSectionHeader from "../components/articleSysemComponents/ArticleSectionHeader";
import ArticleParagraph from "../components/articleSysemComponents/ArticleParagraph";
import ArticleList from "../components/articleSysemComponents/ArticleList";
import CodeSection from "../components/articleSysemComponents/CodeSection";


const StacksArticle = () => {
    return (
        <>
            <Helmet>
                <title>Stack Data Structure</title>
                <meta name="description" content="This is an informative page dedicated to the Stack Data Structure, designed for enthusiasts eager to understand the concept of a stack, explore its implementations, and tackle standard coding problems involving stacks." />
                <meta name="keywords" content="Data Structures, Algorithms, Python, Java, C, C++, JavaScript, Android Development, SQL, Data Science, Machine Learning, PHP, Web Development, System Design, Tutorial, Technical Blogs, Interview Experience, Interview Preparation, Programming, Competitive Programming, Jobs, Coding Contests, GATE CSE, HTML, CSS, React, NodeJS, Placement, Aptitude, Quiz, Computer Science, Programming Examples, GeeksforGeeks Courses, Puzzles, SSC, Banking, UPSC, Commerce, Finance, CBSE, School, k12, General Knowledge, News, Mathematics, Exams" />
            </Helmet>

            <Navbar title={'Stacks'} />



            <div className="article_container">
                <ArticleTitle text={"Understanding Stacks: A Fundamental Data Structure"} />
                <HorizontalLine />
                <ArticleDescription
                    text={
                        "Delve into the world of Stacks, a simple yet powerful data structure, and explore its properties, operations, and applications."
                    }
                />
                <HorizontalLine />
                <ArticleSectionHeader text={"Introduction to Stacks"} />
                <ArticleParagraph
                    text={
                        "A Stack is a linear data structure that follows the Last-In-First-Out (LIFO) principle, where the last element added to the stack is the first one to be removed. It mimics a physical stack of objects, such as a stack of plates."
                    }
                />
                <ArticleParagraph
                    text={
                        "Key characteristics of Stacks include:"
                    }
                />
                <ArticleList
                    items={[
                        "Elements are added and removed from the same end, known as the 'top'.",
                        "Only the top element is accessible for operations.",
                        "Operations include push (addition) and pop (removal).",
                        "Stacks can be implemented using arrays or linked lists.",
                    ]}
                />
                <ArticleSectionHeader text={"Basic Structure of a Stack"} />
                <ArticleParagraph
                    text={
                        "A stack can be visualized as a vertical arrangement of elements, where elements are added or removed from the top."
                    }
                />
                <ArticleSectionHeader text={"Operations on Stacks"} />
                <ArticleParagraph
                    text={
                        "Common operations performed on Stacks include push, pop, peek, and isEmpty."
                    }
                />
                <ArticleSectionHeader text={"Push Operation"} />
                <ArticleParagraph
                    text={
                        "Pushing (or adding) an element onto a stack involves placing the new element at the top of the stack."
                    }
                />
                <ArticleSectionHeader text={"Pop Operation"} />
                <ArticleParagraph
                    text={
                        "Popping (or removing) an element from a stack involves taking out the element at the top of the stack."
                    }
                />
                <ArticleSectionHeader text={"Peek Operation"} />
                <ArticleParagraph
                    text={
                        "Peek allows you to view the top element of the stack without removing it."
                    }
                />
                <ArticleSectionHeader text={"isEmpty Operation"} />
                <ArticleParagraph
                    text={
                        "Checks if the stack is empty or not. Returns true if the stack is empty, false otherwise."
                    }
                />
                <ArticleSectionHeader text={"Applications of Stacks"} />
                <ArticleList
                    items={[
                        "Expression Evaluation: Used in evaluating arithmetic expressions (infix, postfix, prefix).",
                        "Function Call Stack: Keeps track of function calls and returns in programming languages.",
                        "Undo Mechanism: Can be used to implement 'undo' functionality in text editors.",
                        "Backtracking Algorithms: Used in algorithms like Depth-First Search (DFS).",
                        "Memory Management: Used in managing memory allocation and deallocation.",
                    ]}
                />
                <ArticleSectionHeader text={"Advantages of Stacks"} />
                <ArticleList
                    items={[
                        "Simple and easy to implement.",
                        "Efficient for last-in-first-out operations.",
                        "Provides a clear and concise way to manage data flow.",
                        "Supports recursive function calls and backtracking.",
                    ]}
                />
                <ArticleSectionHeader text={"Disadvantages of Stacks"} />
                <ArticleList
                    items={[
                        "Limited in size based on available memory.",
                        "Access to non-top elements requires popping.",
                        "Not efficient for searching or accessing random elements.",
                    ]}
                />
                <ArticleSectionHeader text={"Conclusion"} />
                <ArticleParagraph
                    text={
                        "Stacks are essential data structures that find applications in various fields of computer science, from algorithm design to memory management. Understanding the principles and operations of stacks is crucial for developing efficient and elegant solutions to problems."
                    }
                />

                <QuickQuestion text={"What is a stack?"} choices={["A type of array", "A data structure that follows the LIFO principle", "A type of function", "A type of loop"]} answer={"A data structure that follows the LIFO principle"} questionShown={true} />

                <QuickQuestion text={"What does LIFO stand for in the context of a stack?"} choices={["Last In First Out", "Last In Final Out", "Least In First Out", "Least In Final Out"]} answer={"Last In First Out"} questionShown={true} />

                <QuickQuestion text={"What are the basic operations of a stack?"} choices={["Push, Pop, Peek, and IsEmpty", "Insert, Delete, Update, and Search", "Add, Remove, Check, and IsFull", "None of the above"]} answer={"Push, Pop, Peek, and IsEmpty"} questionShown={true} />

                <QuickQuestion text={"What does the Push operation do in a stack?"} choices={["Removes an element from the stack", "Adds an element to the stack", "Checks the top element of the stack", "Checks if the stack is empty"]} answer={"Adds an element to the stack"} questionShown={true} />

                <QuickQuestion text={"What does the Pop operation do in a stack?"} choices={["Removes an element from the stack", "Adds an element to the stack", "Checks the top element of the stack", "Checks if the stack is empty"]} answer={"Removes an element from the stack"} questionShown={true} />

                <QuickQuestion text={"What does the Peek operation do in a stack?"} choices={["Removes an element from the stack", "Adds an element to the stack", "Checks the top element of the stack", "Checks if the stack is empty"]} answer={"Checks the top element of the stack"} questionShown={true} />

                {/* ask about tiome complexity */}
                <QuickQuestion text={"What is the time complexity of the Push operation?"} choices={["O(1)", "O(n)", "O(log n)", "O(n^2)"]} answer={"O(1)"} questionShown={true} />

                <QuickQuestion text={"What is the time complexity of the Pop operation?"} choices={["O(1)", "O(n)", "O(log n)", "O(n^2)"]} answer={"O(1)"} questionShown={true} />

                <QuickQuestion text={"What is the time complexity of the Peek operation?"} choices={["O(1)", "O(n)", "O(log n)", "O(n^2)"]} answer={"O(1)"} questionShown={true} />

                <QuickQuestion text={"What is the time complexity of the IsEmpty operation?"} choices={["O(1)", "O(n)", "O(log n)", "O(n^2)"]} answer={"O(1)"} questionShown={true} />

            </div>
        </>
    );
};

export default StacksArticle;

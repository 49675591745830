import React from 'react';
import './css/article_complete.css';
const ArticleList = ({ items }) => {
  return (
    <ul className="article_list">
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );
};

export default ArticleList;
